<template>
  <CSidebar :minimize="minimize" unfoldable :show.sync="show">
    <CSidebarBrand />

    <!-- :imgFull="{ width: 118, height: 46, alt: 'Logo', src: 'img/brand/coreui-base-white.svg'}"
    :imgMinimized="{ width: 118, height: 46, alt: 'Logo', src: 'img/brand/coreui-signet-white.svg'}"-->

    <!-- <CSidebarHeader/> -->
    <!-- <CSidebarForm/> -->
    <CRenderFunction flat :contentToRender="nav" />
    <!-- <CSidebarFooter/> -->
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="minimize = !minimize"
    />
  </CSidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import navAdmin1 from "./_navAdmin1";
import navAdmin2 from "./_navAdmin2";
import modules from "./modules";

export default {
  name: "TheSidebar",

  data() {
    return {
      minimize: false,
      show: "responsive",

      users: {
        1: navAdmin1,
        2: navAdmin2
      }
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      isAuthenticated: "auth/is_authenticated"
    }),

    nav() {
      let res = [];

      console.log("user", this.user);
      console.log("user", this.isAuthenticated);

      if (this.isAuthenticated) {
        let permissions = JSON.parse(this.user.permissions);

        console.log("res", permissions);

        for (let permission of permissions) {
          if (modules[permission]) res = [...res, ...modules[permission]]
        }
      } 

      return [
        {
          _name: "CSidebarNav",
          _children: res
        }
      ]
    }
  },

  mounted() {
    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
    });
    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
  }
};
</script>
