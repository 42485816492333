export default [
  {
    _name: "CSidebarNav",
    _children: [
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Dashboard",
      //   to: "/dashboard",
      //   icon: "cil-speedometer",
      //   badge: {
      //     color: "primary",
      //     text: "NEW"
      //   }
      // },

      // {
      //   _name: "CSidebarNavTitle",
      //   _children: []
      // },

      {
        _name: "CSidebarNavItem",
        name: "Luna",
        to: "/luna"
      },

      {
        _name: "CSidebarNavItem",
        name: "Filteroom",
        to: "/filteroom"
      },

      {
        _name: "CSidebarNavItem",
        name: "Presetium",
        to: "/presetium"
      },

      {
        _name: "CSidebarNavItem",
        name: "Filteresco",
        to: "/filteresco"
      },

      {
        _name: "CSidebarNavTitle",
        _children: ["Powerpoint"]
      },
      {
        _name: "CSidebarNavItem",
        name: "Tags",
        to: "/powerpoint/tag"
      },
      {
        _name: "CSidebarNavItem",
        name: "Collection",
        to: "/powerpoint/collection"
      },

      {
        _name: "CSidebarNavTitle",
        _children: ["Instapano"]
      },
      {
        _name: "CSidebarNavItem",
        name: "Tags",
        to: "/instapano/tag"
      },
      {
        _name: "CSidebarNavItem",
        name: "Collection",
        to: "/instapano/collection"
      },

      {
        _name: "CSidebarNavTitle",
        _children: ["CreatorStory"]
      },
      {
        _name: "CSidebarNavItem",
        name: "Tags",
        to: "/creator-story/tag"
      },
      {
        _name: "CSidebarNavItem",
        name: "Collection",
        to: "/creator-story/collection"
      },

      {
        _name: "CSidebarNavTitle",
        _children: ["iconios"]
      },
      {
        _name: "CSidebarNavItem",
        name: "Tags",
        to: "/iconios/tag"
      },
      {
        _name: "CSidebarNavItem",
        name: "Collection",
        to: "/iconios/collection"
      }
    ]
  }
];
