export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavTitle",
        _children: ["Famagic"]
      },
      {
        _name: "CSidebarNavItem",
        name: "Tags",
        to: "/famagic/tag"
      },
      {
        _name: "CSidebarNavItem",
        name: "Collection",
        to: "/famagic/collection"
      }
    ]
  }
];
