<template>
  <CHeader with-subheader>
    <CToggler in-header class="ml-3 d-lg-none" v-c-emit-root-event:toggle-sidebar-mobile/>
    <CToggler in-header class="ml-3 d-md-down-none" v-c-emit-root-event:toggle-sidebar/>
    <CHeaderBrand
      class="mx-auto d-lg-none"
      src="img/brand/coreui-base.svg"
      width="12"
      height="10"
      alt="CoreUI Logo"
    />
    <CHeaderNav class="d-md-down-none mr-auto"></CHeaderNav>

    <CHeaderNav class="ml-3 d-md-down-none">
      <CButton class="m-3 btn-warning btn-block" @click="handlerLogout()">Logout</CButton>
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import { mapActions } from "vuex";

import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import TheHeaderDropdownNotif from "./TheHeaderDropdownNotif";
import TheHeaderDropdownTasks from "./TheHeaderDropdownTasks";
import TheHeaderDropdownMssgs from "./TheHeaderDropdownMssgs";

export default {
  name: "TheHeader",

  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownNotif,
    TheHeaderDropdownTasks,
    TheHeaderDropdownMssgs
  },

  data() {
    return {
      sidebarIsMinimized: false
    };
  },

  computed: {
    logoClasses() {
      return [
        "c-header-brand",
        { "c-header-brand-minimized": this.sidebarIsMinimized }
      ];
    }
  },

  mounted() {
    this.$root.$on("c-sidebar-minimize-state", state => {
      this.sidebarIsMinimized = state;
    });
  },

  methods: {
    ...mapActions({
      logout: "auth/logout"
    }),

    handlerLogout() {
      console.log("handlerLogout");

      this.logout();
    }
  }
};
</script>
