export default {
  presetio: [
    {
      _name: "CSidebarNavItem",
      name: "Luna",
      to: "/luna"
    }
  ],

  filteroom: [
    {
      _name: "CSidebarNavItem",
      name: "Filteroom",
      to: "/filteroom"
    }
  ],

  presetium: [
    {
      _name: "CSidebarNavItem",
      name: "Presetium",
      to: "/presetium"
    }
  ],

  filteresco: [
    {
      _name: "CSidebarNavItem",
      name: "Filteresco",
      to: "/filteresco"
    }
  ],

  powerpoint: [
    {
      _name: "CSidebarNavTitle",
      _children: ["Powerpoint"]
    },
    {
      _name: "CSidebarNavItem",
      name: "Tags",
      to: "/powerpoint/tag"
    },
    {
      _name: "CSidebarNavItem",
      name: "Collection",
      to: "/powerpoint/collection"
    },
    {
      _name: "CSidebarNavItem",
      name: "AI Tags",
      to: "/powerpoint/ai-tag"
    },
    {
      _name: "CSidebarNavItem",
      name: "AI Prompt",
      to: "/powerpoint/ai-prompt"
    },
  ],

  instapano: [
    {
      _name: "CSidebarNavTitle",
      _children: ["Instapano"]
    },
    {
      _name: "CSidebarNavItem",
      name: "Tags",
      to: "/instapano/tag"
    },
    {
      _name: "CSidebarNavItem",
      name: "Collection",
      to: "/instapano/collection"
    },
    {
      _name: "CSidebarNavItem",
      name: "Colors",
      to: "/instapano/color"
    },
    {
      _name: "CSidebarNavItem",
      name: "Textures",
      to: "/instapano/texture"
    },
    {
      _name: "CSidebarNavItem",
      name: "Elements",
      to: "/instapano/element"
    }
  ],

  "creator-story": [
    {
      _name: "CSidebarNavTitle",
      _children: ["CreatorStory"]
    },
    {
      _name: "CSidebarNavItem",
      name: "Tags",
      to: "/creator-story/tag"
    },
    {
      _name: "CSidebarNavItem",
      name: "Colors",
      to: "/creator-story/color"
    },
    {
      _name: "CSidebarNavItem",
      name: "Textures",
      to: "/creator-story/texture"
    },
    {
      _name: "CSidebarNavItem",
      name: "Elements",
      to: "/creator-story/element"
    },
    {
      _name: "CSidebarNavItem",
      name: "Collection",
      to: "/creator-story/collection"
    }
  ],

  iconios: [
    {
      _name: "CSidebarNavTitle",
      _children: ["iconios"]
    },
    {
      _name: "CSidebarNavItem",
      name: "Tags",
      to: "/iconios/tag"
    },
    {
      _name: "CSidebarNavItem",
      name: "Collection",
      to: "/iconios/collection"
    }
  ]
};
